import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import TagLink from '../../components/TagLink';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => {
  const tags = {};
  group.forEach(t => {
    let tag = t.fieldValue.toLowerCase();
    if (!tags[tag] || tags[tag].totalCount < t.totalCount) {
      tags[tag] = t;
    }
  });
  return (
    <Layout>
      <div className="main tags-page">
        <section className="hero">
          <div className="hero-text">
            <h1 className="page-title">Tags</h1>
          </div>
        </section>
        <section>
          <Helmet title={`Tags | ${title}`} />
          <GatsbySeo
            title={`Tags | ${title}`}
            canonical={location.href}
            openGraph={{
              title: `Tags | ${title}`,
              url: `${location.href}`,
            }}
          />
          <div className="content">
            <ul className="taglist tags-blocks">
              {Object.values(tags).map(tag => (
                <li key={tag.fieldValue}>
                  <TagLink tag={tag.fieldValue} count={tag.totalCount} />
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
