import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
const { deslugify } = require('../util/Deslugify');

const TagLink = ({ tag, title, count }) => {
  return (
    <Link
      to={`/tag/${kebabCase(tag)}/`}
      className="taglink"
    >
      {title ? title : deslugify(tag)}
      {count && ` (${count})`}
    </Link>
  );
};

TagLink.propTypes = {
  tag: PropTypes.string.isRequired,
  title: PropTypes.string,
  count: PropTypes.number,
};

export default TagLink;
