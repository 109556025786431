const deslugify = slug => {
  let words = slug.split('-');
  let deslugged = '';
  if (words.length === 1 && words[0].length < 4) {
    deslugged = words[0].toUpperCase();
  } else {
    deslugged = words
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ');
  }

  return deslugged;
};

module.exports.deslugify = deslugify;
